var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vxe-table",
        { attrs: { border: "", "show-overflow": "", data: _vm.value } },
        [
          _c("vxe-table-column", { attrs: { type: "checkbox", width: "60" } }),
          _c("vxe-table-column", {
            attrs: { field: "sort", width: "60", title: "序号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var rowIndex = ref.rowIndex
                  return [_vm._v(" " + _vm._s(rowIndex + 1) + " ")]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: { field: "originModuleName", title: "来源" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "nickName", title: "昵称" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "headImgUrl", title: "头像" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticStyle: { padding: "20px" } }, [
                      _c("img", {
                        staticStyle: { width: "50px", height: "50px" },
                        attrs: { src: row.headImgUrl },
                      }),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: { field: "openId", title: "OpenId" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }