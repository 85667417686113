<!--<template>-->
    <!--<div class="associated_customer">-->
    <!--</div>-->
<!--</template>-->

<script>
// import configs from '../data';
// import Form from '../../../../../components/form';
import TablePage from '../../../../../../components/table_page';
import request from '../../../../../../utils/request';

import associatedCustomerSelectPosition from '../associated_customer/associated_customer_select_position.vue';

export default {
  extends: TablePage,
  props: {
    userItemRowObj: {},

  },
  components: {
    associatedCustomerSelectPosition,
  },

  data() {
    return {
      isCalculateHeight: false,
      activeTabPaneName: 'first',
      // requestUrl: '/mdm/mdmUserController/pageList',
      // configs,
      configs: {
        formConfig: {
          titleWidth: 100,
          titleAlign: 'right',
          item: [],
        },
        toolBarConfig: [
          {
            name: '添加关联',
            buttonType: '0',
            buttonCode: 'addAssociated',
          },
        ],
        columns: [],
        tableConfig: {

        },
      },
    };
  },
  created() {
    this.getConfigList('mdm_user_management_associated_terminals_one', true, true);
  },
  mounted() {

  },
  methods: {
    // 重写 让其不被覆盖
    setButton() {

    },
    // 重新 覆盖 按钮事件

    buttonClick({ val }) {
      if (val.buttonCode === 'addAssociated') {
        if (this.selectRow.length > 0) {
          this.formName = 'associatedCustomerSelectPosition';
          this.modalConfig.title = '选择岗位';
          this.propsObjInData = this.userItemRowObj;
          this.openModal();
        } else {
          this.$message('请勾选');
        }
      }
    },

    onSubmit(positionArr) {
      const params = {
        terminalCodeList: this.selectRow.map((a) => a.terminalCode),
        positionCode: positionArr[0].positionCode,
      };
      request.post('/mdm/mdmUserRelationTerminalController/bindRelation', params).then((res) => {
        if (res.success) {
          this.selectRow = [];
          this.closeModal();
          this.$emit('refresh');
          this.$message({
            message: res.message,
            type: 'success',
          });
        }
      });
    },
  },

};
</script>

<style scoped lang="less">
  .associated_customer{
    /*height: 50vh;*/
  }

</style>
