<template>
    <div class="addUser-position-information">
      <el-form  :disabled="formConfig.btnCode === 'view'"  :model="infoObj" label-position="right" ref="infoObjFormRef" label-width="100px">

        <!--<el-row>-->
          <!--<el-col :span="24">-->
            <!--<div class="arrItemTitle">-->
              <!--<div class="arrItemTitle-left">-->
                <!--<p>-->
                  <!--基本信息-->
                <!--</p>-->
              <!--</div>-->
            <!--</div>-->
          <!--</el-col>-->

          <!--<el-col :span="8">-->
            <!--<el-form-item :rules="[{ required: true, message: '用户名必填'}]" prop="fullName"  label="用户名" >-->
              <!--<el-input   clearable v-model="infoObj.fullName" placeholder="用户名"/>-->
            <!--</el-form-item>-->
          <!--</el-col>-->

          <!--<el-col :span="8">-->
            <!--<el-form-item prop="gender" label="性别">-->
              <!--<el-radio-group   v-model="infoObj.gender">-->
                <!--<el-radio v-for="(item,index) in genderOptionArr" :key="index" :label="item.value">{{item.label}}</el-radio>-->
              <!--</el-radio-group>-->
            <!--</el-form-item>-->
          <!--</el-col>-->

          <!--<el-col :span="8">-->
            <!--<el-form-item-->
              <!--:rules="[-->
              <!--{ required: true, message: '联系电话必填'},-->
              <!--{ pattern: /^1[3456789]\d{9}$/,message: '请输入正确手机号', trigger: 'change'},-->
              <!--]"-->
              <!--prop="userPhone"-->
              <!--label="联系电话">-->
              <!--<el-input   placeholder="联系电话" clearable maxlength="11" v-model="infoObj.userPhone"/>-->
            <!--</el-form-item>-->
          <!--</el-col>-->

          <!--<el-col :span="8">-->
            <!--<el-form-item :rules="[{ required: true, message: '账号必填'},{ pattern: '^[a-zA-Z0-9]*$',message: '仅能输入英文和数字的组合', trigger: 'blur'}]" prop="userName" label="账号">-->
              <!--<el-input clearable :disabled="!!(infoObj.id)" v-model="infoObj.userName" placeholder="账号" auto-complete="new-password"/>-->
            <!--</el-form-item>-->
          <!--</el-col>-->

          <!--<el-col :span="8">-->
            <!--<el-form-item :rules="[{ required: !(infoObj.id), message: '密码必填'}]" prop="userPassword" label="密码">-->
              <!--<Password   v-model="infoObj.userPassword" placeholder="密码"/>-->
              <!--&lt;!&ndash; <el-input v-if="showPassword" ref='password' :type="infoObj.userPassword?'password':'text'" @input="passwordChange"   clearable v-model="infoObj.userPassword" placeholder="密码" auto-complete="new-password"/> &ndash;&gt;-->
            <!--</el-form-item>-->
          <!--</el-col>-->

          <!--<el-col :span="8">-->
            <!--<el-form-item-->
              <!--prop="email"-->
              <!--:rules="[{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }]"-->
              <!--label="邮箱">-->
              <!--<el-input   clearable v-model="infoObj.email" placeholder="邮箱"/>-->
            <!--</el-form-item>-->
          <!--</el-col>-->

          <!--<el-col :span="8">-->
            <!--<el-form-item :rules="[{ required: true, message: '生效时间必填'}]" prop="startTime" label="生效时间">-->
              <!--<el-date-picker-->

                <!--v-model="infoObj.startTime"-->
                <!--:picker-options="{-->
              <!--disabledDate: (time) => {-->
          <!--if (infoObj.endTime) {-->
            <!--return time.getTime() > new Date(infoObj.endTime).getTime();-->
          <!--}-->
        <!--},-->
              <!--}"-->
                <!--value-format="yyyy-MM-dd HH:mm:ss"-->
                <!--style="width: 100%;"-->
                <!--type="datetime"-->
                <!--placeholder="选择日期">-->
              <!--</el-date-picker>-->
            <!--</el-form-item>-->
          <!--</el-col>-->

          <!--<el-col :span="8">-->
            <!--<el-form-item :rules="[{ required: true, message: '失效时间必填'}]" prop="endTime" label="失效时间">-->
              <!--<el-date-picker-->

                <!--v-model="infoObj.endTime"-->
                <!--:picker-options="{-->
              <!--disabledDate: (time) => {-->
          <!--if (infoObj.startTime) {-->
            <!--return time.getTime() < new Date(infoObj.startTime).getTime();-->
          <!--}-->
        <!--},-->
              <!--}"-->
                <!--value-format="yyyy-MM-dd HH:mm:ss"-->
                <!--style="width: 100%;"-->
                <!--type="datetime"-->
                <!--default-time="23:59:59"-->
                <!--placeholder="选择日期">-->
              <!--</el-date-picker>-->
            <!--</el-form-item>-->
          <!--</el-col>-->

        <!--</el-row>-->

        <div>
          <el-row v-for="(item,index) in infoObj.positionList" :key="index">
            <el-col :span="24">
              <div class="arrItemTitle">
                <div class="arrItemTitle-left">
                  <p>
                    职位信息{{index + 1}}
                  </p>
                  <div v-if="!(formConfig.btnCode === 'view')">
                    <el-button v-if="index === 0" type="text" @click="addArrItem(infoObj.userType,infoObj.positionList)">
                      <i style="font-size: 20px" class="el-icon-circle-plus-outline"></i>
                    </el-button>
                    <el-button type="text" v-if="index>0" @click="delArrItem(infoObj.userType,infoObj.positionList,index)">
                      <i style="font-size: 20px" class="el-icon-delete"></i>
                    </el-button>
                  </div>
                </div>

                <div class="arrItemTitle-right">
                  <!--<el-button v-if="index !== 0" type="danger" icon="el-icon-delete" circle @click="delArrItem(infoObj.userType,infoObj.positionList,index)">-->
                  <!--</el-button>-->
                </div>
              </div>
            </el-col>
            <!--<el-col :span="8">-->
            <!--<el-form-item-->
            <!--:prop="'positionList.' + index + '.orgCode'"-->
            <!--:rules="{required: true, message: '组织不能为空', trigger: 'blur'}"-->
            <!--label="组织">-->
            <!--<el-select remote :remote-method="getFindOrgSelectListArr" @focus="getFindOrgSelectListArr('')"   clearable filterable  v-model="item.orgCode" @change="orgCodeSelectChange(item.orgCode,item,index)">-->
            <!--<el-option v-for="(item,index) in findOrgSelectListArr" :key="index" :label="item.label" :value="item.value"></el-option>-->
            <!--</el-select>-->
            <!--</el-form-item>-->
            <!--</el-col>-->
            <el-col :span="8">
              <el-form-item
                :prop="'positionList.' + index + '.orgCode'"
                :rules="{required: true, message: '组织不能为空', trigger: 'change'}"
                label="组织">
                <formSelectTree
                  v-model="item.orgCode"
                   :disabled="formConfig.btnCode === 'view'"
                  :options="findOrgSelectListArr"
                  @focus="getFindOrgSelectListArr('')"
                  @change="orgCodeSelectChange(item.orgCode,item,index)"
                  :props="{
                  parent: 'parentCode',
                  value: 'code',
                  label: 'name',
                  children: 'children',
                  }"
                  placeholder="请选择"></formSelectTree>
                <!--/mdm/baseTreeController/orgTree-->

              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item
                :prop="'positionList.' + index + '.operationType'"
                :rules="{required: true, message: '操作类型不能为空', trigger: 'blur'}"
                label="操作类型">
                <el-select :disabled="(formConfig.btnCode === 'view') || (!item.orgCode)" clearable v-model="item.operationType"  @change="()=>{
                  item.positionLevelCode = '';
                  item.positionLevelName = '';
                  item.positionCode='';
                  item.positionName='';
                  item.parentCode='';
                  item.parentName='';
                  item.primaryFlag='';
                  item.roleList = [];
                  item.bpmRoleList = []
                  }">
                  <el-option v-for="(item,index) in operationTypeOptionArr" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8" v-if="item.operationType === 'n'">
              <el-form-item label="职位级别">
                <el-select remote :remote-method="getPositionLevelSelectListArr" @focus="getPositionLevelSelectListArr('')"   clearable filterable  v-model="item.positionLevelCode" @change="positionLevelCodeSelectChange(item.positionLevelCode,item,index)">
                  <el-option v-for="(item,index) in positionLevelSelectListArr" :key="index" :label="item.label" :value="item.value" ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8" v-if="item.operationType === 'h'">
              <el-form-item label="职位">
                <el-select remote :remote-method="(name)=>{getItemPositionCanUsedToRelateUserListArr(item.orgCode,item,index,name)}"   clearable filterable  v-model="item.positionCode" @change="positionCodeSelectChange(item.positionCode,item,index)">
                  <el-option v-for="(itemItem,itemIndex) in item.itemPositionCanUsedToRelateUserListArr" :key="itemIndex" :label="itemItem.label" :value="itemItem.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="上级">
                <el-select @focus="()=>{getItemPositionSelectListArr(item.orgCode,item,index,'')}" remote
                           :remote-method="(name)=>{getItemPositionSelectListArr(item.orgCode,item,index,name)}"
                             filterable v-model="item.parentCode" clearable>
                  <el-option v-for="(itemItem,itemIndex) in item.itemPositionSelectListArr" :key="itemIndex" :label="itemItem.label" :value="itemItem.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item
                :prop="'positionList.' + index + '.primaryFlag'"
                :rules="{required: true, message: '请选择是否主职位', trigger: 'blur'}"
                label="是否主职位">
                <el-select   clearable v-model="item.primaryFlag">
                  <el-option v-for="(item,index) in primaryFlagOptionArr" :key="index" :label="item.label" :value="item.value" ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="权限角色">
                <el-select filterable :filter-method="getRoleSelectListArr" @visible-change="getRoleSelectListArr('')"      multiple v-model="item.roleList" clearable>
                  <el-option v-for="(item,index) in roleSelectListArr" :key="index" :label="item.label" :value="item.value" ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="流程角色">
                <el-select filterable :filter-method="getBpmRoleListSelectListArr" @focus="getBpmRoleListSelectListArr('')"    multiple v-model="item.bpmRoleList" clearable>
                  <el-option v-for="(item,index) in bpmRoleListSelectListArr" :key="index" :label="item.label" :value="item.value" ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

          </el-row>
        </div>

      </el-form>
      <!--<div class="dialog-footer">-->
        <!--<el-button-->
          <!--v-if="formConfig.btnCode !== 'view'"-->
          <!--type="primary"-->
          <!--@click="submitInfoObj"-->
          <!--size="small"-->
          <!--icon="el-icon-check"-->
        <!--&gt;提交-->
        <!--</el-button>-->
        <!--<el-button-->
          <!--type="danger"-->
          <!--@click="()=>{$emit('onClose')}"-->
          <!--size="small"-->
          <!--icon="el-icon-close"-->
        <!--&gt;关闭-->
        <!--</el-button>-->
      <!--</div>-->
    </div>
</template>

<script>
import request from '../../../../../../../../utils/request';
// import debounce from '../../../../../../utils/debounce';
// import Password from '../../../../../../../../components/form/components/form_password.vue';
import formSelectTree from '../../../../../../../../components/form/components/form_select_tree.vue';

export default {
  // extends: Form,
  name: 'addUser_position_info',
  components: {
    formSelectTree,
    // Password,
  },
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
    id: String,
    row: Object,
    formConfig: {
      type: Object,
      default: () => ({}),
    },
    propsInfoObj: {
      type: Object,
      default: () => ({}),
    },

  },
  data() {
    return {
      passwordDefault: '******',
      infoObj: {
        id: '',
        userType: '',
        fullName: '',
        gender: '',
        userPhone: '',
        userName: '',
        userPassword: '',
        email: '',
        startTime: '',
        endTime: '',
        customerCode: '',
        roleCode: '',
        positionList: [
          {
            operationType: '',
            orgCode: '',
            parentCode: '',
            positionCode: '',
            positionLevelCode: '',
            primaryFlag: '',
            roleList: [],
            bpmRoleList: [],
            itemPositionCanUsedToRelateUserListArr: [],
            itemPositionSelectListArr: [],
          },
        ],

      },
      showPassword: true,
      findCustomerSelectListArr: [], // 关联客户的下拉选项 数组
      roleSelectListArr: [], // 角色的下拉选项 数组
      bpmRoleListSelectListArr: [], // 流程角色的下拉选项 数组
      findOrgSelectListArr: [], // 组织的下拉选项 数组
      // 操作类型
      operationTypeOptionArr: [],
      // 是否主职位
      primaryFlagOptionArr: [],
      positionLevelSelectListArr: [], // 职位级别的下拉选项 数组
      positionSelectListArr: [], // 上级职位的下拉选项 数组
      positionCanUsedToRelateUserListArr: [], // 职位的下拉选项 数组
      userTypeOptionArr: [], // 用户类型下拉选项
      genderOptionArr: [], // 用户类型下拉选项
    };
  },
  async created() {
    this.getAllOptionArr();
    if (this.formConfig && this.formConfig.id) {
      this.getUserInfoObjDetails(this.formConfig.id);
    }
    this.getRoleSelectListArr();
    this.getFindOrgSelectListArr();
    this.getPositionLevelSelectListArr();
    this.getBpmRoleListSelectListArr();
  },
  watch: {
    formConfig(formConfig) {
      if (formConfig && formConfig.id) {
        this.getUserInfoObjDetails(formConfig.id);
      }
    },
    propsInfoObj: {
      deep: true,
      // immediate: true,
      handler(propsInfoObj) {
        const newObjData = propsInfoObj;
        delete newObjData.positionList;
        this.infoObj = {
          ...this.infoObj,
          ...newObjData,
        };
      },

    },
    'infoObj.positionList': {
      deep: true,
      immediate: true,
      handler(positionList) {
        this.$emit('input', positionList);
        this.$emit('change', positionList);
      },
    },
  },
  mounted() {},
  methods: {
    // 密码框change事件
    passwordChange(e) {
      if (!e) {
        this.showPassword = false;
        setTimeout(() => {
          this.showPassword = true;
          this.$nextTick(() => {
            this.$refs.password.focus();
          });
        }, 1);
      }
    },
    // 根据 id  获取 详情
    async getUserInfoObjDetails(id = '') {
      request.get('/mdm/mdmUserController/detail', { id }).then((res) => {
        if (res.success) {
          let handlePositionList = (res.result.positionList || []).map((a) => ({
            ...a,
            itemPositionCanUsedToRelateUserListArr: [],
            itemPositionSelectListArr: [],
            roleList: a.roleList.map((b) => (b.roleCode)),
            bpmRoleList: a.bpmRoleList.map((b) => (b.bpmRoleCode)),
          }));
          if (handlePositionList.length === 0) {
            handlePositionList = [
              {
                operationType: '',
                orgCode: '',
                parentCode: '',
                positionCode: '',
                positionLevelCode: '',
                primaryFlag: '',
                roleList: [],
                bpmRoleList: [],
                itemPositionCanUsedToRelateUserListArr: [],
                itemPositionSelectListArr: [],
              },
            ];
          }

          this.infoObj = {
            ...res.result,
            positionList: handlePositionList,
          };

          handlePositionList.forEach((a, index) => {
            // 职位
            request.post('/mdm/mdmUserController/findUserPositionSelectList', {
              thisOrgCode: a.orgCode,
              includeUserName: res.result.userName,
              enableStatus: '009',
              selectedCode: a.positionCode,
            }).then((res2) => {
              if (res2.success) {
                this.$nextTick(() => {
                  this.infoObj.positionList[index].itemPositionCanUsedToRelateUserListArr = res2.result.map((a2) => ({ label: a2.unionName, value: a2.positionCode }));
                });
              }
            });
            // 上级
            request.post('/mdm/mdmUserController/findUserParentPositionSelectList', {
              childPositionOrgCode: a.orgCode,
              enableStatus: '009',
              // excludeUserName: res.result.userName || '',
              excludePositionCode: a.positionCode,
              childPositionCode: a.positionCode || '',
              selectedCode: a.parentCode,
            }).then((res2) => {
              if (res2.success) {
                this.$nextTick(() => {
                  this.infoObj.positionList[index].itemPositionSelectListArr = res2.result.map((a2) => ({ label: a2.unionName, value: a2.positionCode }));
                });
              }
            });

            // 组织
            this.getFindOrgSelectListArr('', a.orgCode);
            // 职位级别
            this.getPositionLevelSelectListArr('', a.positionLevelCode);
            // 角色
            this.getRoleSelectListArr('', a.roleList);
            // 流程角色
            this.getBpmRoleListSelectListArr('', a.bpmRoleList);
          });

          this.infoObj.userPassword = this.passwordDefault;
        }
      });
    },

    // 获取 数据字典下拉选项
    async getAllOptionArr() {
      this.userTypeOptionArr = await this.getMdmdictDataSelectOptions('user_type');
      this.genderOptionArr = await this.getMdmdictDataSelectOptions('gender');
      this.operationTypeOptionArr = await this.getMdmdictDataSelectOptions('position_operation');
      this.primaryFlagOptionArr = await this.getMdmdictDataSelectOptions('yes_or_no');
    },

    // =获取 数据字典
    async getMdmdictDataSelectOptions(dictCode) {
      const res = await request.post('/mdm/mdmdictdata/list', { dictTypeCode: dictCode });
      if (res.success) {
        return res.result.map((a) => ({ label: a.dictValue, value: a.dictCode }));
      }
      return [];
    },

    // 职位选择框改变
    positionCodeSelectChange(code, item, index) {
      request.get('/mdm/mdmPositionController/detail', { positionCode: code }).then((res) => {
        if (res.result) {
          this.infoObj.positionList[index].primaryFlag = res.result.primaryFlag;
          this.infoObj.positionList[index].roleList = (res.result.roleList || []).map((a) => a.roleCode);
          this.infoObj.positionList[index].bpmRoleList = (res.result.bpmRoleList || []).map((a) => a.bpmRoleCode);
          this.getItemPositionSelectListArr(item.orgCode, item, index, '', res.result.parentCode);
        }
      });
    },

    // 职位级别改变
    positionLevelCodeSelectChange(code, item, index) {
      // 获取职位级别详情
      request.get('/mdm/mdmPositionLevelController/detail', { positionLevelCode: code }).then((res) => {
        if (res.result) {
          this.infoObj.positionList[index].roleList = (res.result.roleList || []).map((a) => a.roleCode);
        }
      });
    },

    // 职位模糊搜索
    getItemPositionCanUsedToRelateUserListArr(code, item, index, unionName) {
      if (code) {
        request.post('/mdm/mdmUserController/findUserPositionSelectList', {
          thisOrgCode: code, enableStatus: '009', unionName,
        }).then((res) => {
          if (res.success) {
            this.$nextTick(() => {
              this.infoObj.positionList[index].itemPositionCanUsedToRelateUserListArr = res.result.map((a) => ({ label: a.unionName, value: a.positionCode }));
            });
          }
        });
      }
    },
    // 上级模糊搜索
    // 选择上级：/mdm/mdmUserController/findUserParentPositionSelectList
    getItemPositionSelectListArr(code, item, index, unionName, value) {
      if (code) {
        request.post('/mdm/mdmUserController/findUserParentPositionSelectList', {
          childPositionOrgCode: code,
          enableStatus: '009',
          unionName,
          childPositionCode: item.positionCode,
          // excludeUserName: this.infoObj.userName,
          excludePositionCode: item.positionCode,
        }).then((res) => {
          if (res.success) {
            this.$nextTick(() => {
              this.infoObj.positionList[index].itemPositionSelectListArr = res.result.map((a) => ({ label: a.unionName, value: a.positionCode }));
            });
            console.log(value);
            if (value !== undefined) {
              this.infoObj.positionList[index].parentCode = value;
            }
          }
        });
      }
    },

    // 组织选择框改变
    orgCodeSelectChange(code, item, index) {
      this.infoObj.positionList[index].operationType = '';
      this.infoObj.positionList[index].parentCode = '';
      this.infoObj.positionList[index].positionCode = '';
      this.infoObj.positionList[index].roleList = [];
      this.infoObj.positionList[index].bpmRoleList = [];
      this.infoObj.positionList[index].itemPositionSelectListArr = [];
      this.infoObj.positionList[index].itemPositionCanUsedToRelateUserListArr = [];
      if (code) {
        // 上级
        // this.getItemPositionSelectListArr(code, item, index, '');

        // 职位
        this.getItemPositionCanUsedToRelateUserListArr(code, item, index, '');
      }
    },

    // 获取职位级别下拉P
    getPositionLevelSelectListArr(positionLevelName = '', selectedCode) {
      request.post('/mdm/mdmPositionLevelController/selectList', {
        enableStatus: '009',
        positionLevelName,
        selectedCode,
      }, { headers: { functionCode: 'select-job-level' } }).then((res) => {
        if (res.success) {
          this.positionLevelSelectListArr = res.result.map((a) => ({ label: a.positionLevelName, value: a.positionLevelCode }));
        }
      });
    },

    // 获取组织下拉
    getFindOrgSelectListArr(name, selectedCode) {
      // request.post('/mdm/mdmOrgController/select', { enableStatus: '009', orgName: name, selectedCode }).then((res) => {
      //   if (res.success) {
      //     this.findOrgSelectListArr = res.result.map((a) => ({ ...a, label: a.orgName, value: a.orgCode }));
      //   }
      // });
      // 树形
      request.post('/mdm/baseTreeController/orgTree', {
        enableStatus: '009',
        orgName: name,
        selectedCode,
      }, { headers: { functionCode: 'select-org' } }).then((res) => {
        if (res.success) {
          // this.findOrgSelectListArr = res.result.map((a) => ({ ...a, label: a.orgName, value: a.orgCode }));
          this.findOrgSelectListArr = res.result;
        }
      });
    },

    //  获取流程角色下拉选项

    getBpmRoleListSelectListArr(name, selectedCodeList) {
      request.post('/mdm/mdmBpmRoleController/selectList', {
        enableStatus: '009',
        bpmRoleName: name,
        selectedCodeList,
      }, { headers: { functionCode: 'select-process-role' } }).then((res) => {
        if (res.success) {
          this.bpmRoleListSelectListArr = res.result.map((a) => ({ label: a.bpmRoleName, value: a.bpmRoleCode }));
        }
      });
    },

    // 获取角色下拉
    getRoleSelectListArr(name, selectedCodeList) {
      request.post('/mdm/mdmRoleController/roleSelectList', {
        enableStatus: '009',
        roleName: name,
        selectedCodeList,
      }, { headers: { functionCode: 'select-permissions-role' } }).then((res) => {
        if (res.success) {
          this.roleSelectListArr = res.result.map((a) => ({ label: a.roleName, value: a.roleCode }));
        }
      });
    },

    // 判定验证信息是否通过
    submitInfoObj() {
      let isRight = false;
      this.$refs.infoObjFormRef.validate((valid) => {
        if (valid) {
          isRight = valid;
        }
      });
      return isRight;
    },

    //  新增每一i想
    addArrItem(userType, arr) {
      arr.push(
        {
          operationType: '',
          orgCode: '',
          parentCode: '',
          positionCode: '',
          positionLevelCode: '',
          primaryFlag: '',
          roleList: [],
          itemPositionCanUsedToRelateUserListArr: [],
          itemPositionSelectListArr: [],
        },
      );
    },
    // 删除每一项
    delArrItem(userType, arr, index) {
      // if (arr.length <= 1) {
      //   this.$message.error('必须保留一条数据');
      //   return;
      // }
      this.$confirm('确认删除？', '提示').then(() => {
        arr.splice(index, 1);
        // if (arr.length === 0) {
        //   arr.push(
        //     {
        //       operationType: '',
        //       orgCode: '',
        //       parentCode: '',
        //       positionCode: '',
        //       positionLevelCode: '',
        //       primaryFlag: '',
        //       roleList: [],
        //       itemPositionCanUsedToRelateUserListArr: [],
        //       itemPositionSelectListArr: [],
        //     },
        //   );
        // }
      });
    },

  },

};
</script>

<style scoped lang="less">
/deep/.el-button--small.is-circle{
  padding: 5px!important;
}
/deep/.el-form-item__label{
  font-size: 12px!important;
}
  .addUser-position-information{
    width: 100%;
    .footer-btn {
      position: absolute;
      bottom: 0;
      right: 10px;

      button {
        width: 100px;
      }
    }

    .arrItemTitle{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .arrItemTitle-left{
        display: flex;
        align-items: center;
        justify-content: center;
        p{
          margin: 0 20px 0 0;
          font-size: 16px;
          height: 20px;
          line-height: 20px;
          font-weight: 600;
        }

      }
      .arrItemTitle-right{

      }

    }

  }

</style>
