var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addUser-position-information" },
    [
      _c(
        "el-form",
        {
          ref: "infoObjFormRef",
          attrs: {
            disabled: _vm.formConfig.btnCode === "view",
            model: _vm.infoObj,
            "label-position": "right",
            "label-width": "100px",
          },
        },
        [
          _c(
            "div",
            _vm._l(_vm.infoObj.positionList, function (item, index) {
              return _c(
                "el-row",
                { key: index },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "arrItemTitle" }, [
                      _c("div", { staticClass: "arrItemTitle-left" }, [
                        _c("p", [
                          _vm._v(" 职位信息" + _vm._s(index + 1) + " "),
                        ]),
                        !(_vm.formConfig.btnCode === "view")
                          ? _c(
                              "div",
                              [
                                index === 0
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addArrItem(
                                              _vm.infoObj.userType,
                                              _vm.infoObj.positionList
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-circle-plus-outline",
                                          staticStyle: { "font-size": "20px" },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                index > 0
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delArrItem(
                                              _vm.infoObj.userType,
                                              _vm.infoObj.positionList,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          staticStyle: { "font-size": "20px" },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "arrItemTitle-right" }),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "positionList." + index + ".orgCode",
                            rules: {
                              required: true,
                              message: "组织不能为空",
                              trigger: "change",
                            },
                            label: "组织",
                          },
                        },
                        [
                          _c("formSelectTree", {
                            attrs: {
                              disabled: _vm.formConfig.btnCode === "view",
                              options: _vm.findOrgSelectListArr,
                              props: {
                                parent: "parentCode",
                                value: "code",
                                label: "name",
                                children: "children",
                              },
                              placeholder: "请选择",
                            },
                            on: {
                              focus: function ($event) {
                                return _vm.getFindOrgSelectListArr("")
                              },
                              change: function ($event) {
                                return _vm.orgCodeSelectChange(
                                  item.orgCode,
                                  item,
                                  index
                                )
                              },
                            },
                            model: {
                              value: item.orgCode,
                              callback: function ($$v) {
                                _vm.$set(item, "orgCode", $$v)
                              },
                              expression: "item.orgCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "positionList." + index + ".operationType",
                            rules: {
                              required: true,
                              message: "操作类型不能为空",
                              trigger: "blur",
                            },
                            label: "操作类型",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled:
                                  _vm.formConfig.btnCode === "view" ||
                                  !item.orgCode,
                                clearable: "",
                              },
                              on: {
                                change: function () {
                                  item.positionLevelCode = ""
                                  item.positionLevelName = ""
                                  item.positionCode = ""
                                  item.positionName = ""
                                  item.parentCode = ""
                                  item.parentName = ""
                                  item.primaryFlag = ""
                                  item.roleList = []
                                  item.bpmRoleList = []
                                },
                              },
                              model: {
                                value: item.operationType,
                                callback: function ($$v) {
                                  _vm.$set(item, "operationType", $$v)
                                },
                                expression: "item.operationType",
                              },
                            },
                            _vm._l(
                              _vm.operationTypeOptionArr,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  item.operationType === "n"
                    ? _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "职位级别" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    remote: "",
                                    "remote-method":
                                      _vm.getPositionLevelSelectListArr,
                                    clearable: "",
                                    filterable: "",
                                  },
                                  on: {
                                    focus: function ($event) {
                                      return _vm.getPositionLevelSelectListArr(
                                        ""
                                      )
                                    },
                                    change: function ($event) {
                                      return _vm.positionLevelCodeSelectChange(
                                        item.positionLevelCode,
                                        item,
                                        index
                                      )
                                    },
                                  },
                                  model: {
                                    value: item.positionLevelCode,
                                    callback: function ($$v) {
                                      _vm.$set(item, "positionLevelCode", $$v)
                                    },
                                    expression: "item.positionLevelCode",
                                  },
                                },
                                _vm._l(
                                  _vm.positionLevelSelectListArr,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  item.operationType === "h"
                    ? _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "职位" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    remote: "",
                                    "remote-method": function (name) {
                                      _vm.getItemPositionCanUsedToRelateUserListArr(
                                        item.orgCode,
                                        item,
                                        index,
                                        name
                                      )
                                    },
                                    clearable: "",
                                    filterable: "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.positionCodeSelectChange(
                                        item.positionCode,
                                        item,
                                        index
                                      )
                                    },
                                  },
                                  model: {
                                    value: item.positionCode,
                                    callback: function ($$v) {
                                      _vm.$set(item, "positionCode", $$v)
                                    },
                                    expression: "item.positionCode",
                                  },
                                },
                                _vm._l(
                                  item.itemPositionCanUsedToRelateUserListArr,
                                  function (itemItem, itemIndex) {
                                    return _c("el-option", {
                                      key: itemIndex,
                                      attrs: {
                                        label: itemItem.label,
                                        value: itemItem.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "上级" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                remote: "",
                                "remote-method": function (name) {
                                  _vm.getItemPositionSelectListArr(
                                    item.orgCode,
                                    item,
                                    index,
                                    name
                                  )
                                },
                                filterable: "",
                                clearable: "",
                              },
                              on: {
                                focus: function () {
                                  _vm.getItemPositionSelectListArr(
                                    item.orgCode,
                                    item,
                                    index,
                                    ""
                                  )
                                },
                              },
                              model: {
                                value: item.parentCode,
                                callback: function ($$v) {
                                  _vm.$set(item, "parentCode", $$v)
                                },
                                expression: "item.parentCode",
                              },
                            },
                            _vm._l(
                              item.itemPositionSelectListArr,
                              function (itemItem, itemIndex) {
                                return _c("el-option", {
                                  key: itemIndex,
                                  attrs: {
                                    label: itemItem.label,
                                    value: itemItem.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "positionList." + index + ".primaryFlag",
                            rules: {
                              required: true,
                              message: "请选择是否主职位",
                              trigger: "blur",
                            },
                            label: "是否主职位",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "" },
                              model: {
                                value: item.primaryFlag,
                                callback: function ($$v) {
                                  _vm.$set(item, "primaryFlag", $$v)
                                },
                                expression: "item.primaryFlag",
                              },
                            },
                            _vm._l(
                              _vm.primaryFlagOptionArr,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "权限角色" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                "filter-method": _vm.getRoleSelectListArr,
                                multiple: "",
                                clearable: "",
                              },
                              on: {
                                "visible-change": function ($event) {
                                  return _vm.getRoleSelectListArr("")
                                },
                              },
                              model: {
                                value: item.roleList,
                                callback: function ($$v) {
                                  _vm.$set(item, "roleList", $$v)
                                },
                                expression: "item.roleList",
                              },
                            },
                            _vm._l(
                              _vm.roleSelectListArr,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "流程角色" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                "filter-method":
                                  _vm.getBpmRoleListSelectListArr,
                                multiple: "",
                                clearable: "",
                              },
                              on: {
                                focus: function ($event) {
                                  return _vm.getBpmRoleListSelectListArr("")
                                },
                              },
                              model: {
                                value: item.bpmRoleList,
                                callback: function ($$v) {
                                  _vm.$set(item, "bpmRoleList", $$v)
                                },
                                expression: "item.bpmRoleList",
                              },
                            },
                            _vm._l(
                              _vm.bpmRoleListSelectListArr,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }