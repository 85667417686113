<script>
import TablePage from '../../../../../../components/table_page';

export default {
  extends: TablePage,
  props: {
    propsObj: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      configs: {
        formConfig: {
          titleWidth: 100,
          titleAlign: 'right',
          item: [],
        },
        toolBarConfig: [
          {
            name: '确认',
            buttonType: '0',
            buttonCode: 'submitAssociated',
          },
        ],
        columns: [],
        tableConfig: {},
      },
      isCalculateHeight: false,
    };
  },
  created() {
    this.tablePage = {
      ...this.tablePage,
      pageSize: 200,
    };
    this.params = { ...this.params, userName: this.propsObj.userName };
    this.getConfigList('associated_customer_select_position', true, true);
  },
  mounted() {
  },
  methods: {
    // 重写 让其不被覆盖
    setButton() {

    },
    // 重新 覆盖 按钮事件

    buttonClick({ val }) {
      if (val.buttonCode === 'submitAssociated') {
        if (this.selectRow.length > 0) {
          this.$emit('submit', this.selectRow);
        } else {
          this.$message('请勾选');
        }
      }
    },
  },

};
</script>

<style scoped lang="less">
  .associated_customer{
    /*height: 50vh;*/
  }

</style>
