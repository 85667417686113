<script>
import TablePage from '../../../../../../components/table_page';
import request from '../../../../../../utils/request';

import associatedCustomerSelectPosition from './associated_customer_select_position.vue';

export default {
  extends: TablePage,
  components: {
    associatedCustomerSelectPosition,
  },
  props: {
    userItemRowObj: {},
  },
  data() {
    return {
      activeTabPaneName: 'first',
      isCalculateHeight: false,
      configs: {
        formConfig: {
          titleWidth: 100,
          titleAlign: 'right',
          item: [],
        },
        toolBarConfig: [
          {
            name: '添加关联',
            buttonType: '0',
            buttonCode: 'addAssociated',
          },
        ],
        columns: [],
        tableConfig: {

        },
      },
    };
  },
  created() {
    this.getConfigList('mdm_user_management_associated_customer_one', true, true);
    if (this.userItemRowObj.enableStatus !== '009') {
      this.configs.toolBarConfig = [];
    }
  },
  mounted() {

  },
  methods: {
    // 重写 让其不被覆盖
    setButton() {

    },
    // 重新 覆盖 按钮事件

    buttonClick({ val }) {
      console.log(this.userItemRowObj);
      if (val.buttonCode === 'addAssociated') {
        if (this.selectRow.length > 0) {
          this.formName = 'associatedCustomerSelectPosition';
          this.modalConfig.title = '选择岗位';
          this.propsObjInData = this.userItemRowObj;
          this.openModal();
        } else {
          this.$message('请勾选');
        }
      }
    },

    // 选好职位后触发提交
    onSubmit(positionArr) {
      const params = {
        customerCodeList: this.selectRow.map((a) => a.customerCode),
        positionCode: positionArr[0].positionCode,
      };
      request.post('/mdm/mdmUserRelationCustomerController/bindRelation', params).then((res) => {
        if (res.success) {
          this.selectRow = [];
          this.$emit('refresh');
          this.closeModal();
          this.$message({
            message: res.message,
            type: 'success',
          });
        }
      });
    },

  },

};
</script>

<style scoped lang="less">
  .associated_customer{
    /*height: 50vh;*/
  }

</style>
