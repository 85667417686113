<script>
import TablePage from '../../../../../components/table_page';
import configs from '../data';
import Form from '../form';
import associatedCustomer from '../form/associated_customer/associated_customer.vue';
// 关联设备
import associatedTerminals from '../form/associated_terminals/associated_terminals.vue';
import lookSubordinates from '../form/look_subordinates/look_subordinates_box.vue';
// 新增用户
import addUser from '../form/add_user/index';

import Modal from '../../../../../components/modal';
import resetPassword from '../form/components/reset_password/reset_password.vue';
import request from '../../../../../utils/request';
// 解绑微信
import unbindWecharts from '../form/unbindWeChats/index.vue';

export default {
  extends: TablePage,
  components: {
    Modal,
    Form,
    associatedCustomer,
    associatedTerminals,
    lookSubordinates,
    addUser,
    resetPassword,
    unbindWecharts,
  },
  props: {
    treeCode: String,
  },
  watch: {
    treeCode(val) {
      console.log(val);
      this.params.code = val;
      this.getList();
    },
  },
  data() {
    return {
      requestUrl: '/mdm/mdmUserExtController/pageInfoList',
      configs,
      formName: 'Form',
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  created() {
    this.getTableConfig();
  },
  methods: {
    setColumn(v) {
      const rows = v;
      if (rows.field === 'positionLevelName') { // 职位级别
        rows.isSearch = true;
        rows.apiUrl = '/mdm/mdmPositionLevelController/pageList';
        rows.paramsName = 'positionLevelName';
        rows.optionsKey = {
          label: 'positionLevelName',
          value: 'positionLevelName',
        };
      }
      return rows;
    },

    // 获取表格配置信息
    getTableConfig() {
      this.getConfigList('mdm_user_management');
    },
    beforeSearchEvent(val) {
      if (val.$event.type === 'reset') {
        this.$emit('reset');
      }
      return true;
    },
    beforeModalClick({ val }) {
      if (val.code === 'enable' || val.code === 'disable' || val.code === 'delete') {
        this.resetTree();
        return false;
      }
      return true;
    },
    modalClick({ val, row }) {
      if (!this.beforeModalClick({ val, row })) return;
      this.formConfig = {};
      if (val.code === 'add') {
        // this.formName = 'Form';
        this.formName = 'addUser';
        this.formConfig = { code: val.code };
        this.modalConfig.title = '新增';
        this.openFull();
      }
      if (val.code === 'edit') {
        // this.formName = 'Form';
        this.formName = 'addUser';
        this.formConfig = row;
        this.modalConfig.title = '编辑';
        this.openFull();
      }
      // 查看
      if (val.code === 'view') {
        this.formName = 'addUser';
        this.formConfig = { ...row, btnCode: 'view', code: 'view' };
        this.modalConfig.title = '查看';
        this.openFull();
      }

      if (val.buttonName === '关联客户') {
        this.formConfig = row;
        this.formName = 'associatedCustomer';
        this.modalConfig.title = '关联客户';
        this.openFull();
      }
      if (val.code === 'associated_terminal') {
        this.formConfig = row;
        this.formName = 'associatedTerminals';
        this.modalConfig.title = '关联终端';
        this.openFull();
      }
      if (val.code === 'view_subordinates') {
        this.propsObjInData = row;
        this.formName = 'lookSubordinates';
        this.modalConfig.title = '查看下属';
        this.openModal();
      }
      // 重置密码
      if (val.code === 'reset_password') {
        if (this.selectRow.length > 0) {
          // 禁止修改 sysadmin 的密码
          const userNames = this.selectRow.map((i) => i.userName);
          if (userNames.includes('sysadmin')) {
            this.$message.error('不能修改 sysadmin 账号密码！');
            return;
          }
          this.formName = 'Form';
          this.formConfig = {
            type: val.code,
            selectRow: this.selectRow,
          };
          this.modalConfig.title = '重置密码';
          this.modalConfig.width = '600px';
          this.modalConfig.height = '200px';
          this.openModal();
        }
        // this.$message('最少选择一条数据操作');
      }
      // 解绑微信
      if (val.code === 'unbind_wechat_item') {
        request.post('/mdm/mdmUserRelWeChatController/findUserRelWeChatList', {
          userName: row.userName,
        }).then((res) => {
          if (res.success) {
            if (!res.result.length) {
              this.$message.error('当前用户没用绑定微信');
            } else if (res.result.length === 1) {
              this.unbindWechart(res.result[0].id);
            } else {
              this.formName = 'unbindWecharts';
              this.modalConfig = {
                title: '解绑微信',
                showFooter: true,
                buttons: {
                  sure: true, // 确定按钮
                  close: true, // 关闭按钮
                },
                userName: row.userName,
              };
              this.openModal();
            }
          }
        });
      }
    },
    // 解绑微信接口
    unbindWechart(id) {
      request.post('/mdm/mdmUserRelWeChatController/unbindUserWeChat', [id]).then((res) => {
        if (res.success) {
          this.$message.success('解绑成功');
        }
      });
    },
  },
};
</script>
