<template>
  <div>
    <vxe-table
                border
                show-overflow
                :data="value"
              >
                <vxe-table-column type="checkbox" width="60"></vxe-table-column>
                <vxe-table-column field="sort" width="60" title="序号">
                  <template v-slot="{ rowIndex }">
                    {{ rowIndex + 1 }}
                  </template>
                </vxe-table-column>
                <vxe-table-column field="originModuleName" title="来源"></vxe-table-column>
                <vxe-table-column field="nickName" title="昵称"></vxe-table-column>
                <vxe-table-column field="headImgUrl" title="头像">
                  <template v-slot="{ row }">
                    <div style="padding: 20px">
                    <img :src="row.headImgUrl"  style="width:50px; height:50px">
                    </div>
                  </template>
                </vxe-table-column>
                <vxe-table-column field="openId" title="OpenId"></vxe-table-column>
              </vxe-table>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="less" scoped>
  /deep/ .vxe-body--row {
    height: 100px !important;
  }
  /deep/ .vxe-body--column{
    max-height: 100px !important;
  }
  /deep/ .vxe-cell {
    max-height: 100px !important;
  }
  /deep/ .c--tooltip{
    max-height: 100px !important;
  }
</style>
