var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: { modalConfig: _vm.modalConfig },
          on: { onClose: _vm.onClose, onOk: _vm.onOk },
        },
        [
          _c("Table", {
            ref: "table",
            attrs: { userName: _vm.modalConfig.userName },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }