<!--<template>-->
<!--<div></div>-->
<!--</template>-->

<script>
// import configs from '../data';
// import Form from '../../../../../components/form';
import TablePage from '../../../../../../components/table_page';
// import request from '../../../../../../utils/request';

export default {
  extends: TablePage,
  props: {
    propsObj: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      requestUrl: '/mdm/mdmPositionUnderlingController/findParentPositionList',
      params: {
        enableStatus: '009',
        excludeChildPositionCodeList: this.propsObj.selectRow.map((a) => a.underlingPositionCode) || [],
      },
      // configs,
      configs: {
        formConfig: {
          titleWidth: 100,
          titleAlign: 'right',
          item: [],
        },
        toolBarConfig: [
          // {
          //   name: '取消',
          //   buttonType: '0',
          //   buttonCode: 'cancel',
          //
          // },
          {
            name: '确认',
            buttonType: '0',
            buttonCode: 'submitReplace',

          },
        ],
        columns: [],
        tableConfig: {},
      },
    };
  },
  created() {
    this.getConfigList('look_subordinates_select_superior', true, true);
  },
  mounted() {},
  methods: {
    // 重写 让其不被覆盖
    setButton() {},
    // 重新 覆盖 按钮事件

    buttonClick({ val }) {
      if (val.buttonCode === 'submitReplace') {
        if (this.selectRow.length > 0) {
          this.$emit('submit', this.selectRow);
        } else {
          this.$message('请勾选');
        }
      }

      if (val.buttonCode === 'cancel') {
        this.$emit('onClose');
      }
    },
  },
};
</script>

<style scoped lang="less">
.associated_customer {
  /*height: 50vh;*/
}
</style>
