import TablePage from '../../../../../../components/table_page';
import request from '../../../../../../utils/request';

export default {
  extends: TablePage,
  props: {
    userName: String,
  },
  data() {
    return {
      requestUrl: '/mdm/mdmUserRelWeChatController/findUserRelWeChatList',
      isCalculateHeight: false,
    };
  },
  components: {
  },
  created() {
    this.getConfigList('bind_wechat_list');
  },
  methods: {
    setColumn(col) {
      const rowData = col;

      rowData.className = 'formatter-image';
      if (rowData.field === 'headImgUrl') {
        rowData.type = 'html';
        rowData.formatter = ({ cellValue }) => `<img src="${cellValue}" />`;
      }
      return rowData;
    },
    beforeGetList() {
      this.searchFormData = {
        userName: this.userName,
      };
      return true;
    },
  },
};
