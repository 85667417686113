<template>
  <div class="associated_customer">
    <div class="associated_customer_up">
      <el-tabs v-model="activeTabPaneName" type="card" @tab-click="() => {}">
        <el-tab-pane label="未关联任何用户的终端" name="associatedCustomerOne">
          <associatedCustomerOne
            ref="one"
            @refresh="refresh"
            :userItemRowObj="formConfig"
          ></associatedCustomerOne>
        </el-tab-pane>
        <el-tab-pane label="未关联当前用户的终端" name="associatedCustomerTwo">
          <associatedCustomerTwo
            ref="two"
            @refresh="refresh"
            :userItemRowObj="formConfig"
          ></associatedCustomerTwo>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="associated_customer_line"></div>
    <div class="associated_customer_down">
      <el-tabs v-model="activeTabPaneNameDown" type="card" @tab-click="() => {}">
        <el-tab-pane label="已关联该用户的终端" name="associatedCustomerThree">
          <associatedCustomerThree
            ref="three"
            @refresh="refresh"
            :userItemRowObj="formConfig"
          ></associatedCustomerThree>
        </el-tab-pane>
      </el-tabs>
    </div>

    <div class="dialog-footer">
      <div class="associated_customer_Btn">
        <el-button @click="$emit('onClose')" type="danger" size="small" icon="el-icon-close">关闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Form from '../../../../../../components/form';
import associatedCustomerOne from './associated_terminals_one.vue';
import associatedCustomerTwo from './associated_terminals_two.vue';
import associatedCustomerThree from './associated_terminals_three.vue';

export default {
  extends: Form,
  components: {
    associatedCustomerOne,
    associatedCustomerTwo,
    associatedCustomerThree,
  },
  data() {
    return {
      activeTabPaneName: 'associatedCustomerOne',
      activeTabPaneNameDown: 'associatedCustomerThree',
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 联动刷新列表
    refresh() {
      this.$refs.one.getList();
      this.$refs.two.getList();
      this.$refs.three.getList();
    },
  },
};
</script>

<style scoped lang="less">
.associated_customer {
  /*height: 50vh;*/
  .associated_customer_line {
    height: 2px;
    width: 100%;
    background-color: #aaa;
    margin: 30px 0;
  }
  .footer-btn {
    position: absolute;
    bottom: 0;
    right: 10px;

    // button {
    //   width: 100px;
    // }
  }
}
</style>
