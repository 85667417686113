<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

import positionInfo from './components/position_info/position_info.vue';
import bindWechat from './components/bind_wechat.vue';
import DealerList from './dealer_list.vue';

formCreate.component('positionInfo', positionInfo);
formCreate.component('bindWechat', bindWechat);
formCreate.component('DealerList', DealerList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      passwordDefault: '******',
      infoObj: {
        id: '',
        userType: '',
        fullName: '',
        gender: '',
        userPhone: '',
        userName: '',
        userPassword: '',
        email: '',
        startTime: '',
        endTime: '',
        customerCode: '',
        roleCode: '',

      },
      showData: null,
      ext13Validate: null,
    };
  },
  watch: {

  },
  async created() {
    const res = await this.getFormRule('mdm_user_management_form');
    const ext13 = this.getRule('ext13');
    this.ext13Validate = ext13.validate;
    res.forEach((item) => {
      if (item.field === 'endTime') {
        Object.assign(item, {
          ...this.props,
          props: {
            type: 'datetime',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            relation: {
              beginField: 'startTime',
              endField: 'endTime',
              gtNow: true,
            },
          },
        });
      }
      if (item.field === 'startTime') {
        Object.assign(item, {
          ...this.props,
          props: {
            type: 'datetime',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            relation: {
              beginField: 'startTime',
              endField: 'endTime',
              gtNow: true,
            },
          },
        });
      }
      if (item.field === 'email') {
        // eslint-disable-next-line no-param-reassign
        item.validate = [
          ...item.validate,
          {
            trigger: 'blur',
            type: 'email',
            message: '请输入正确的邮箱',
          },
        ];
      }
      if (item.field === 'ext13') { // 经销商名称
        item.props = {
          ...item.props,
          params: {
            functionCode: 'dealer_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: item.title,
        };
      }
    });
    const positionList = this.getRule('positionList');
    const userName = this.getRule('userName');
    const userPhone = this.getRule('userPhone');
    if (this.formConfig.code !== 'view') {
      this.fApi.hidden(true, ['relWeChatList', 'wechatTitle']);
    }
    // 是否经销商业务员
    const ext11 = this.getRule('ext11');
    if (this.formConfig.code === 'add') { // 默认为否
      this.setValue({
        ext11: '0',
        ext12: null,
        ext13: null,
      });
      ext13.validate = null;
    }
    ext11.on = {
      change: (e) => {
        if (e === '1') { // 是， 则隐藏职位信息， 打开经销商选项 ，反之亦然
          // this.fApi.hidden(true, ['positionList']);
          ext13.validate = this.ext13Validate;
          this.disabled(false, ['ext13']);
          // console.log('ext13', ext13);
          // this.fApi.hidden(false, ['ext13', 'ext14']);
        } else if (e === '0') {
          // this.fApi.hidden(false, ['positionList']);
          ext13.validate = null;
          this.disabled(e, ['ext13']);
          // console.log('ext13', ext13);
          // this.fApi.hidden(true, ['ext13', 'ext14']);
        }
      },
    };
    // 经销商

    ext13.on = {
      change: (e) => {
        if (e.length > 0) {
          this.setValue({
            ext12: e[0].customerCode,
            ext13: e[0].customerName,
          });
        } else {
          this.setValue({
            ext12: null,
            ext13: null,
          });
        }
      },
    };

    // 存在 对职位信息赋值
    if (positionList) {
      positionList.props = {
        ...positionList.props,
        formConfig: this.formConfig,
        propsInfoObj: this.infoObj,
      };
    }

    // 电话号码
    if (userPhone) {
      userPhone.props = {
        ...userPhone.props,
        maxlength: 11,
      };
      userPhone.validate = [
        ...userPhone.validate,
        { pattern: /^1[3456789]\d{9}$/, message: '请输入正确手机号', trigger: 'change' },
      ];
    }

    // 编辑或查看
    if (this.formConfig.id) {
      this.getUserInfoObjDetails(this.formConfig.id);

      if (userName) {
        userName.props = {
          ...userName.props,
          disabled: true,
        };
      }
    } else {
      this.setValue({
        endTime: '9999-12-31 23:59:59',
      });
    }

    this.rule = res;
    this.reload(this.rule);
  },
  methods: {

    async getUserInfoObjDetails(id = '') {
      request.get('/mdm/mdmUserController/detail', { id }).then((res) => {
        if (res.success) {
          const { result } = res;
          delete result.positionList;
          this.infoObj = {
            ...result,
            userPassword: this.passwordDefault,
          };
          const ext13 = this.getRule('ext13');
          if (result.ext11 === '1') { // 是， 则隐藏职位信息， 打开经销商选项 ，反之亦然
            // this.fApi.hidden(true, ['positionList']);
            ext13.validate = this.ext13Validate;
            this.disabled(false, ['ext13']);
            // this.fApi.hidden(false, ['ext13']);
            // console.log('ext13', ext13);
          } else if (result.ext11 === '0') {
            // this.fApi.hidden(false, ['positionList']);
            ext13.validate = null;
            this.disabled(true, ['ext13']);
            // this.fApi.hidden(true, ['ext13']);
          }
          setTimeout(() => {
            this.setValue(this.infoObj);
          }, 100);
        }
      });
    },

    // 提交
    submit() {
      const formData = this.getFormData();
      if (formData) {
        // if (formData.ext11 === '1') { // 是经销商业务员,
        //   formData.positionList = [];
        //   this.againConfirmSubmit(formData);
        // } else if (formData.ext11 === '0') {
        //   let flag = false;
        //   formData.positionList.forEach((item) => {
        //     if (item.orgCode && item.operationType && item.primaryFlag) {
        //       flag = true;
        //     }
        //   });
        //   if (flag) {
        //     this.againConfirmSubmit(formData);
        //   } else {
        //     this.$message.error('请填写完整表单');
        //   }
        // }
        let flag = false;
        (formData.positionList || []).forEach((item) => {
          if (item.orgCode && item.operationType && item.primaryFlag) {
            flag = true;
          }
        });
        if (flag) {
          this.againConfirmSubmit(formData);
        } else {
          this.$message.error('请填写完整表单');
        }
      }
    },
    // 再次确认提交
    againConfirmSubmit(formData) {
      this.infoObj = {
        ...this.infoObj,
        ...formData,
      };

      // 默认新增
      let url = '/mdm/mdmUserController/save';
      // 有id  就是编辑
      if (this.infoObj.id) {
        url = '/mdm/mdmUserController/update';
        this.infoObj.userPassword = this.infoObj.userPassword === this.passwordDefault ? '' : this.infoObj.userPassword;
      }
      const params = this.infoObj;

      request.post(url, params).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });
          this.$emit('onClose');
          this.$emit('resetTree');
        }
      });
    },
  },
};
</script>
