<template>
  <div>
    <Modal :modalConfig="modalConfig" @onClose="onClose" @onOk="onOk">
      <Table ref="table" :userName="modalConfig.userName"/>
    </Modal>
  </div>
</template>

<script>
import request from '../../../../../../utils/request';
import Modal from '../../../../../../components/modal/components/modal.vue';
import Table from './table';

export default {
  components: {
    Modal,
    Table,
  },
  props: {
    modalConfig: {
      type: Object,
      default() {
        return {
          title: '解绑微信',
          visible: false,
          showFooter: true,
        };
      },
    },
  },
  methods: {
    onClose() {
      this.$emit('onClose');
    },
    onOk() {
      const { selection } = this.$refs.table;
      if (selection.length === 0) {
        this.$message.error('请选择需要解绑的微信');
        return false;
      }
      request.post('/mdm/mdmUserRelWeChatController/unbindUserWeChat', selection).then((res) => {
        if (res.success) {
          this.$message.success('解绑成功');
          this.onClose();
        }
      });
    },
  },
};
</script>

<style></style>
